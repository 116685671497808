const Block_Scr = () => {
    return (
        <div className="md:h-[120vh]  h-[150vh]  flex items-center justify-center bg-white">
            <div className="w-[80%]">

                <script src="https://apps.elfsight.com/p/platform.js" defer></script>

                <div class="elfsight-app-a6327392-5465-4f9c-8170-4d93c1c55778"></div>
            </div>

        </div>
    );
}

export default Block_Scr